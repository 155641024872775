import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";


const url = "https://agrovisio.us17.list-manage.com/subscribe/post?u=1f684d45d625792505a066d20&amp;id=0fb6ae75f1";
const CustomForm = ({ status, message, onValidated }) => {
    let email, name;
    const submit = () =>
        email &&
        name &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value,
            PHONE: name.value
        });
    return (
        <div className="content loginContainer" onClick={function(event) {
            if (event.target == document.getElementById('myModal')) {
                document.getElementById('myModal').style.display = "none";
            }}}>
                <div id="myModal" className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={function() {
                            document.getElementById('myModal').style.display = "none";
                        }}>&times;</span>
                        <iframe src="../detail.html" onLoad="alert('Test');"></iframe>
                    </div>
                </div>
                {showMailingPopUp(status, message)}
                <div className="login type1">
                    <div className="input-wrapper">
                        <i className="fa fa-envelope" />
                        <input className="textbox" name="mail" id="mail" placeholder="Eposta" ref={node => (email = node)} type="email"/>
                    </div>
                </div>
                <div className={"type2"}>
                    <input
                        ref={node => (name = node)}
                        type="checkbox" id = "checkBox"
                        name="checkbox" onClick={function() {
                        var elementValue =document.getElementById('checkBox').value
                        if (elementValue === "false") {
                            document.getElementById('checkBox').value = "true";
                        }
                        else if(elementValue === "true"){
                            document.getElementById('checkBox').value = "false";
                        }
                    }}

                    /> Agrovisio'nun gelişmelerinden haberdar olmak istiyorum.
                    <p><a onClick={function() {
                        document.getElementById('myModal').style.display = "block";
                    }}>Kişisel Verilerin Korunması Hakkında</a></p>
                </div>
                <div className="login-options">
                    <button type="button" className="login-btn" id={"open-popup"} onClick = {submit}>Giriş</button>
                </div>
        </div>
    );
};

/* && (
                <div id={"result"}
                    style={{ color: "red" }}
                    dangerouslySetInnerHTML={{ __html: message }}

                />
            )*/

function showMailingPopUp(status, messages) {
    if (status === "success" || status === "error") {
        localStorage.setItem('login', "true");
        window.location.reload();
    }
};




export class Login extends React.Component {
    constructor(props) {
        super(props);
        this.doLogin = this.doLogin.bind(this);
    }


    componentDidMount()
    {
        var elem = document.getElementById('checkBox');
        if(elem !== null) {
            elem.value= "true"
            elem.checked = true
        }
    }

    doLogin(status){
        //{submit}
        const mail = document.getElementById('mail').value;
        if (mail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) !== null) {
            this.props.doLogin(mail);
        }
        else{
            alert('Lütfen geçerli bir Eposta adresi giriniz...');
        }

    }

    render() {
        return (
                <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                        <CustomForm
                            status={status}
                            message={message}
                            onValidated={formData => subscribe(formData)}
                        />
                    )}
                />
        )
    }
}
