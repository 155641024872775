import React from 'react';
import '../node_modules/leaflet/dist/leaflet.css';
import L from '../node_modules/leaflet/dist/leaflet.js';
import '../node_modules/leaflet.active-layers/src/ActiveLayers.js'
import fileLayer from '../node_modules/leaflet-filelayer/src/leaflet.filelayer.js';
import togeojson from '../node_modules/togeojson/togeojson.js';
import App from "./App";
import  './leaflet-plugins/layer/vector/KML.js'
import '../node_modules/font-awesome/css/font-awesome.css'
import '../node_modules/leaflet-groupedlayercontrol/src/leaflet.groupedlayercontrol.js'

const legendUrunList = L.control({position: 'topright'});
export class Map extends React.Component {
    constructor() {
        super();
        this.state = {
            filterValue: 0,
            urunFilterValue: 0,
            centerValue: 0,
            filterBunyeValues: ["Killi", "Orta", "Kumlu", "Cakilli"],
            filterBunyeValuesText: ["Killi", "Orta", "Kumlu", "Çakıllı"],
            filterOneriValues: ["MAP yada TSP, AN, AS, CAN, KNO3 Ürününüze uygun uygulama için danışınız!", "DAP, AN, AS, CAN, KNO3 ve K2SO4 + (mikro element gübreleme ihtiyacı) Ürününüze uygun uygulama için danışınız!", "DAP, AN, AS, CAN, KNO3 ve K2SO4 Ürününüze uygun uygulama için danışınız!"],
            filterOneriValuesText: ["G1", "G2 + mikro element", "G2"],
            filterSFGvalues: ["F1", "F2", "F3", "F4", "F5", "DogalTarimDisi", "SuYuzeyi", "YolYerlesim"],
            filterSFGvaluesText: ["Çok Yüksek", "Yüksek", "Orta", "Düşük", "Çok Düşük", "Doğal Tarım Dışı", "Su Yüzeyi", "Yol Yerleşim"],
            filterUrunvalues: ["Ayçiçeği", "Arpa", "Buğday", "Haşhaş", "Mısır", "Şeker Pancarı", "Patates", "Diğer"],
            filterUrunvaluesText: ["Ayçiçeği", "Arpa", "Buğday", "Haşhaş", "Mısır", "Şeker Pancarı", "Patates", "Diğer"],
            filterBugdayvalues: ["İyi Buğday", "Zayıf Buğday"],
            filterBugdayvaluesText: ["İyi Buğday", "Zayıf Buğday"],
            filterHashasvalues: ["Haşhaş (Kışlık)", "Haşhaş (Yazlık)"],
            filterHashasvaluesText: ["Haşhaş (Kışlık)", "Haşhaş (Yazlık)"],
            selected: null,
            popupContent: ""
        };
        this.setPoint = this.setPoint.bind(this);
        this.sendLayerContent = this.sendLayerContent.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
        this.onMapDblClick = this.onMapDblClick.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleUrunFilter = this.handleUrunFilter.bind(this);
        this.handleCenter = this.handleCenter.bind(this);
        this.clickToFeature = this.clickToFeature.bind(this);
        this.mapZoomEnd = this.mapZoomEnd.bind(this);
        //this.createMap = this.createMap.bind(this);
        //this.createFilter = this.createFilter.bind(this);
        //this.getColor = this.getColor.bind(this);
    }
    setPoint(point1, point2, dblClckValue) {
        this.props.setPoints(point1, point2, dblClckValue);
    }
    sendLayerContent(layerContent) {
        this.props.sendLayerContent(layerContent);
    }
    onMapClick(e) {
        this.setPoint(e.latlng.lat, e.latlng.lng, 0);
    }
    onMapDblClick(e) {
        this.setPoint(e.latlng.lat, e.latlng.lng, 1);
    }
    readPlacemark(xmlData, filter, filterUrun) {
        var Folder = xmlData.getElementsByTagName("Folder");
        for (var m = 0; m < Folder.length; m++) {
            var Placemark = Folder[m].getElementsByTagName("Placemark");
            var campus;
            var outerLine = 0.25;
            let mahalle_array = [];
            let mahalle_alan_valuesArray = [];
            let mahalle_mainIndex = 0;
            let alan_mainIndex = 0;
            let ilce_array = [];
            let ilce_alan_valuesArray = [];
            let ilce_mainIndex = 0;
            let mahalle_filter_array = [];
            let mahalle_filter_alan_valuesArray = [];
            let filter_mainIndex = 0;
            let firstValue = true;
            let Alan;
            for (var j = 0; j < Placemark.length; j++) {
                var MultiGeometry = Placemark[j].getElementsByTagName("Polygon");
                var outerBoundaryIs = MultiGeometry[0].getElementsByTagName("outerBoundaryIs");
                var outerBoundaryIsCoordinates = outerBoundaryIs[0].getElementsByTagName("coordinates")[0].firstChild.data;
                var outerCoordinates = outerBoundaryIsCoordinates.split(" ");
                var outerCoordinates_ = outerCoordinates;
                for (var k = 0; k < outerCoordinates.length; k++) {
                    outerCoordinates_[k] = outerCoordinates[k].split(",");
                }
                var innerBoundaryIs = MultiGeometry[0].getElementsByTagName("innerBoundaryIs");
                var innerCoordinates = "";
                try {
                    var innerBoundaryIsCoordinates = innerBoundaryIs[0].getElementsByTagName("coordinates")[0].firstChild.data;
                    innerCoordinates = innerBoundaryIsCoordinates.split(" ");
                    for (k = 0; k < innerCoordinates.length; k++) {
                        innerCoordinates[k] = innerCoordinates[k].split(",");
                    }
                }
                catch (err) {
                }
                var ExtendedData = Placemark[j].getElementsByTagName("ExtendedData");
                var SchemaData = ExtendedData[0].getElementsByTagName("SchemaData");
                var SimpleData = SchemaData[0].getElementsByTagName("SimpleData");
                try {
                    let filter_alan_valuesArray = [];
                    if (firstValue) {
                        for (k = 0; k < SimpleData.length; k++) {
                            if (SimpleData[k].attributes.name.nodeValue === "Mahalle") {
                                mahalle_mainIndex = k;
                                mahalle_array.push(SimpleData[mahalle_mainIndex].innerHTML);
                            }
                            else if (SimpleData[k].attributes.name.nodeValue === "İlçe") {
                                ilce_mainIndex = k;
                                ilce_array.push(SimpleData[ilce_mainIndex].innerHTML);
                            }
                            else if (SimpleData[k].attributes.name.nodeValue === filter) {
                                filter_mainIndex = k;
                                mahalle_filter_array.push(SimpleData[mahalle_mainIndex].innerHTML + "_" + SimpleData[filter_mainIndex].innerHTML);
                            }
                            else if (SimpleData[k].attributes.name.nodeValue === "Alan") {
                                alan_mainIndex = k;
                                Alan = SimpleData[alan_mainIndex].innerHTML;
                                if (Alan.includes("m2")) {
                                    Alan = Alan.toString().substring(0, Alan.indexOf("m2") - 1).replace(",", "").replace(".", ",")
                                }

                                mahalle_alan_valuesArray.push(parseFloat(Alan.replace(',', '.')) / 1000);
                                ilce_alan_valuesArray.push(parseFloat(Alan.replace(',', '.')) / 1000);
                                filter_alan_valuesArray.push(parseFloat(Alan.replace(',', '.')) / 1000);

                                mahalle_filter_alan_valuesArray.push(filter_alan_valuesArray);
                            }
                            firstValue = false;
                        }
                    }
                    else {
                        Alan = SimpleData[alan_mainIndex].innerHTML;
                        if (Alan.includes("m2")) {
                            Alan = Alan.toString().substring(0, Alan.indexOf("m2") - 1).replace(",", "").replace(".", ",")
                        }
                        if (mahalle_array.indexOf(SimpleData[mahalle_mainIndex].innerHTML) < 0) {
                            mahalle_array.push(SimpleData[mahalle_mainIndex].innerHTML);
                            mahalle_alan_valuesArray.push(parseFloat(Alan.replace(',', '.')) / 1000);
                        }
                        else {
                            mahalle_alan_valuesArray[mahalle_array.indexOf(SimpleData[mahalle_mainIndex].innerHTML)]
                                += parseFloat(Alan.replace(',', '.')) / 1000;
                        }

                        if (ilce_array.indexOf(SimpleData[ilce_mainIndex].innerHTML) < 0) {
                            ilce_array.push(SimpleData[ilce_mainIndex].innerHTML);
                            ilce_alan_valuesArray.push(parseFloat(Alan.replace(',', '.')) / 1000);
                        }
                        else {
                            ilce_alan_valuesArray[ilce_array.indexOf(SimpleData[ilce_mainIndex].innerHTML)]
                                += parseFloat(Alan.replace(',', '.')) / 1000;
                        }

                        if (mahalle_filter_array.indexOf(SimpleData[mahalle_mainIndex].innerHTML + "_" + SimpleData[filter_mainIndex].innerHTML) < 0) {
                            if (SimpleData[filter_mainIndex].innerHTML !== "") {
                                mahalle_filter_array.push(SimpleData[mahalle_mainIndex].innerHTML + "_" + SimpleData[filter_mainIndex].innerHTML);
                                filter_alan_valuesArray.push(parseFloat(Alan.replace(',', '.')) / 1000);
                                mahalle_filter_alan_valuesArray.push(filter_alan_valuesArray);
                            }
                        }
                        else {
                            mahalle_filter_alan_valuesArray[mahalle_filter_array.indexOf(SimpleData[mahalle_mainIndex].innerHTML + "_" + SimpleData[filter_mainIndex].innerHTML)] =
                                parseFloat(mahalle_filter_alan_valuesArray[mahalle_filter_array.indexOf(SimpleData[mahalle_mainIndex].innerHTML + "_" + SimpleData[filter_mainIndex].innerHTML)])
                                + parseFloat(Alan.replace(',', '.') / 1000);
                        }
                    }
                }
                catch (err) {
                    console.log(err.message);
                }
            }
            for (j = 0; j < Placemark.length; j++) {
                MultiGeometry = Placemark[j].getElementsByTagName("Polygon");
                outerBoundaryIs = MultiGeometry[0].getElementsByTagName("outerBoundaryIs");
                outerBoundaryIsCoordinates = outerBoundaryIs[0].getElementsByTagName("coordinates")[0].firstChild.data;
                outerCoordinates = outerBoundaryIsCoordinates.split(" ");
                outerCoordinates_ = outerCoordinates;
                for (k = 0; k < outerCoordinates.length; k++) {
                    outerCoordinates_[k] = outerCoordinates[k].split(",");
                }
                innerBoundaryIs = MultiGeometry[0].getElementsByTagName("innerBoundaryIs");
                innerCoordinates = "";
                try {
                    innerBoundaryIsCoordinates = innerBoundaryIs[0].getElementsByTagName("coordinates")[0].firstChild.data;
                    innerCoordinates = innerBoundaryIsCoordinates.split(" ");
                    for (k = 0; k < innerCoordinates.length; k++) {
                        innerCoordinates[k] = innerCoordinates[k].split(",");
                    }
                }
                catch (err) {
                }
                ExtendedData = Placemark[j].getElementsByTagName("ExtendedData");
                SchemaData = ExtendedData[0].getElementsByTagName("SchemaData");
                SimpleData = SchemaData[0].getElementsByTagName("SimpleData");
                var popupContentText = "";
                var fillColorValue = "#00000000";
                try {
                    let simpleDataColumnsForFilter = 0;
                    for (k = 0; k < SimpleData.length; k++) {
                        popupContentText += "<b>" + SimpleData[k].attributes.name.nodeValue + ": </b>";
                        if (SimpleData[k].attributes.name.nodeValue === "Alan" && SimpleData[k].innerHTML.includes("m2")) {
                            let Alan = SimpleData[k].innerHTML;
                            Alan = Alan.toString().substring(0, Alan.indexOf("m2") - 1).replace(",", "").replace(".", ",")
                            popupContentText += Alan + "<br/>";
                        }
                        else {
                            popupContentText += SimpleData[k].innerHTML + "<br/>";
                        }
                        if (SimpleData[k].attributes.name.nodeValue === filter) {
                            simpleDataColumnsForFilter = k;
                        }
                    }
                    popupContentText += "<b>mahalle_alan: </b>";
                    popupContentText += mahalle_alan_valuesArray[mahalle_array.indexOf(SimpleData[mahalle_mainIndex].innerHTML)] + "<br/>";
                    popupContentText += "<b>ilce_alan: </b>";
                    popupContentText += ilce_alan_valuesArray[ilce_array.indexOf(SimpleData[ilce_mainIndex].innerHTML)] + "<br/>";
                    popupContentText += "<b>filter: </b>";
                    popupContentText += filter + "<br/>";
                    for (var i = 0; i < mahalle_filter_array.length; i++) {
                        popupContentText += "<b>" + mahalle_filter_array[i] + ": </b>";
                        popupContentText += mahalle_filter_alan_valuesArray[i] + "<br/>";
                    }

                    switch (filter) {
                        case "Bunye": {
                            for (k = 0; k < this.state.filterBunyeValues.length; k++) {
                                if (SimpleData[simpleDataColumnsForFilter].innerHTML === this.state.filterBunyeValues[k]) {
                                    fillColorValue = this.getColorBunye(k);
                                    break
                                }
                            }
                            break
                        }
                        case "GubOner": {
                            for (k = 0; k < this.state.filterOneriValues.length; k++) {
                                if (SimpleData[simpleDataColumnsForFilter].innerHTML === this.state.filterOneriValues[k]) {
                                    fillColorValue = this.getColorOneri(k);
                                    break
                                }
                            }
                            break
                        }
                        case "SFG": {
                            for (k = 0; k < this.state.filterSFGvalues.length; k++) {
                                if (SimpleData[simpleDataColumnsForFilter].innerHTML === this.state.filterSFGvalues[k]) {
                                    fillColorValue = this.getColorSFG(k);
                                    break
                                }
                            }
                            break
                        }
                        default: {
                            break
                        }
                    }
                }
                catch (err) {
                    console.log(err.message);
                }

                let opacityValue_2 = .8
                let opacityValue_1 = .9;


                campus = {
                    "type": "Feature",
                    "properties": {
                        "popupContent":
                        popupContentText,
                        "style": {
                            weight: outerLine,
                            color: "#1b1a1a",
                            opacity: opacityValue_1,
                            fillColor: fillColorValue,
                            fillOpacity: opacityValue_2
                        }
                    },
                    "geometry": {
                        "type": "MultiPolygon",
                        "coordinates": [
                            [outerCoordinates_, innerCoordinates]
                        ]
                    }
                };
                try {
                    L.geoJSON([campus], {
                        style: function (feature) {
                            return feature.properties && feature.properties.style;
                        }, onEachFeature: this.onEachFeature.bind(this)

                    }).addTo(App.map);
                }
                catch (err) {
                    console.log(err.message);
                }
            }
        }
    }

    getAllXml(filter, filterUrun){
        this.createMap(App.map.getCenter().lat, App.map.getCenter().lng, App.map.getZoom());
        this.getXmlDatas(filter, filterUrun, "map.kml");
    }

    getXmlDatas(filter, filterUrun, kmlPath) {
        this.xml = new XMLHttpRequest();
        this.xml.open('GET', kmlPath, false);
        this.xml.send();
        let xmlData = this.xml.responseXML;
        if (!xmlData) {
            xmlData = (new DOMParser()).parseFromString(this.xml.responseText, 'text/xml');
        }
        this.readPlacemark(xmlData, filter, filterUrun);
    }
    onEachFeature(feature, layer) {
        //layer.bindPopup(feature.properties.popupContent);
        layer.on({
            click: this.clickToFeature.bind(this)
        });
    }
    clickToFeature(e) {
        let previous;
        if (this.state.selected !== null) {
            previous = this.state.selected;
        }
        if (previous) {
            if (this.state.selected === null || this.state.selected._leaflet_id !== e.target._leaflet_id) {
                previous.setStyle({
                    weight: .25,
                    dashArray: '',
                    color: "#1b1a1a",
                    opacity: .9,
                });
            }
        }
        e.target.setStyle({
            weight: 5,
            dashArray: '',
            color: "#fff"
        });
        this.setState({popupContent: e.target.feature.properties.popupContent});
        this.sendLayerContent(e.target.feature.properties.popupContent);

        this.setState({selected: e.target});

        if (!L.Browser.ie && !L.Browser.opera) {
            e.target.bringToFront();
        }
    }
    mapZoomEnd(e) {
        /*var zoom = e.target.getZoom();
        console.log(e.target.attributionControl._map._layers);

        console.log(this.state.popupContent);
        if (zoom > 10 && zoom <= 7) {
            for (i = 0; i < e._layers.length; i++) {
                e._layers[i].setStyle({
                    weight: .15,
                    dashArray: '',
                    color: "#1b1a1a",
                    opacity: .9,
                });
            }
        }
        else if(zoom <= 10) {
            for (i = 0; i < e._layers.length; i++) {
                e._layers[i].setStyle({
                    weight: .15,
                    dashArray: '',
                    color: "#1b1a1a",
                    opacity: .9,
                });
            }
        }
        else if(zoom > 7) {
            for (i = 0; i < e._layers.length; i++) {
                e._layers[i].setStyle({
                    weight: .05,
                    dashArray: '',
                    color: "#1b1a1a",
                    opacity: .9,
                });
            }
        }*/
    }
    createMap(_lan, _lot, _zoom) {
        try {
            if (App.map != null) {
                App.map.remove();
                App.map = null;
            }
            App.map = L.map('map', {center: [_lan, _lot], zoom: _zoom});
            //App.map.doubleClickZoom.disable();
            App.map.attributionControl.setPrefix('');
            App.map.on({zoomend: this.mapZoomEnd.bind(this)});
            fileLayer(null, L, togeojson)
            L.tileLayer('http://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {maxZoom: 20,subdomains: ['mt0', 'mt1', 'mt2', 'mt3']}).addTo(App.map);
            //L.tileLayer('https://megsistile.tkgm.gov.tr/tkgm.ows.tile/tms/1.0.0/TKGM:parsel_tematik_3857/{z}/{x}/{y}.png',).addTo(App.map);

            //App.map.on('click', this.onMapClick);
            //App.map.on('dblclick', this.onMapDblClick);
            this.createapCenterFilter();
            this.createFilter();
            this.createUrunFilterComboBox();
            this.createColorLegend();
            //this.createKmlUpload();
            this.createWatermark();
        }
        catch (err) {
            console.log(err.message);
        }
    }
    createapCenterFilter() {
        var legend = L.control({position: 'topright'});
        legend.onAdd = function (map) {
            var div = L.DomUtil.create('div', 'info legend');
            div.innerHTML = '<i class="fa fa-filter selectIcon .droplist"></i><select id=\'centerList\' class="form-control form-control-sm droplist">' +
                '<option value="" selected disabled>Merkez Seçimi:</option>'+
                '<option value="38.298002,30.176467,13">Afyon</option>' +
                '<option value="37.574628,27.327764,14">Aydın</option>' +
                '<option value="37.968193,32.906950,14">Konya</option>' +
                '</select>';
            return div;
        };
        legend.addTo(App.map);
        document.getElementById("centerList").selectedIndex = this.state.centerValue;
        document.getElementById("centerList").addEventListener("change", this.handleCenter, false);
    }
    createColorLegend() {
        var legend = L.control({position: 'bottomright', paddingBottom: '150px'});
        let filterValuesArray;
        let colorArray = [];
        switch (this.state.filterValue) {
            case 0: {
                filterValuesArray = this.state.filterBunyeValuesText;
                for (var i = 0; i < filterValuesArray.length; i++) {
                    colorArray.push(this.getColorBunye(i));
                }
                break
            }
            case 1: {
                filterValuesArray = this.state.filterOneriValuesText;
                for (i = 0; i < filterValuesArray.length; i++) {
                    colorArray.push(this.getColorOneri(i));
                }
                break
            }
            case 2: {
                filterValuesArray = this.state.filterSFGvaluesText;
                for (i = 0; i < filterValuesArray.length; i++) {
                    colorArray.push(this.getColorSFG(i));
                }
                break
            }
            case 3: {
                switch (this.state.urunFilterValue) {
                    case 3: {
                        filterValuesArray = this.state.filterBugdayvaluesText;
                        for (i = 0; i < filterValuesArray.length; i++) {
                            colorArray.push(this.getColorBugday(i));
                        }
                        break
                    }
                    case 4: {
                        filterValuesArray = this.state.filterHashasvaluesText;
                        for (i = 0; i < filterValuesArray.length; i++) {
                            colorArray.push(this.getColorHashas(i));
                        }
                        break
                    }
                    default : {
                        filterValuesArray = this.state.filterUrunvaluesText;
                        for (i = 0; i < filterValuesArray.length; i++) {
                            colorArray.push(this.getColorURUN(i));
                        }
                        break
                    }
                }
                break
            }
            default:
            {
                break
            }
        }
        legend.onAdd = function (map) {
            var div = L.DomUtil.create('div', 'info2 legend2')
            for (i = 0; i < filterValuesArray.length; i++) {
                div.innerHTML +=
                    '<i style="background:' + colorArray[i] + '"></i> ' +
                    filterValuesArray[i] + '<br><div style="height: 5px"/>';
            }
            return div;
        };
        legend.addTo(App.map);
    }
    createFilter() {
        var legend = L.control({position: 'topright'});
        legend.onAdd = function (map) {
            var div = L.DomUtil.create('div', 'info legend');
            div.innerHTML = '<i class="fa fa-filter selectIcon .droplist"></i><select id=\'filterList\' class="form-control form-control-sm droplist">' +
                '<option value="Bunye">Bünye</option>' +
                '<option value="GubOner">Öneri</option>' +
                '<option value="SFG">Verim</option>' +
                '</select>';
            //div.firstChild.onmousedown = div.firstChild.ondblclick = L.DomEvent.stopPropagation;
            return div;
        };
        legend.addTo(App.map);

        document.getElementById("filterList").selectedIndex = this.state.filterValue;
        document.getElementById("filterList").addEventListener("change", this.handleFilter, false);
    }
    createUrunFilterComboBox(){
        legendUrunList.onAdd = function (map) {
            var div = L.DomUtil.create('div', 'info legend');
            div.innerHTML = '<i class="fa fa-filter selectIcon .droplist"></i><select id=\'urunFilterList\' class="form-control form-control-sm droplist">' +
                '<option value="Hepsi" selected>Hepsi</option>' +
                '<option value="Ayçiçeği">Ayçiçeği</option>' +
                '<option value="Arpa">Arpa</option>' +
                '<option value="Buğday">Buğday</option>' +
                '<option value="Haşhaş">Haşhaş</option>' +
                '<option value="Mısır">Mısır</option>' +
                '<option value="Şeker Pancarı">Şeker Pancarı</option>' +
                '<option value="Patates">Patates</option>' +
                '<option value="Diğer">Diğer</option>' +
                '</select>';
            //div.firstChild.onmousedown = div.firstChild.ondblclick = L.DomEvent.stopPropagation;
            return div;
        };
        legendUrunList.addTo(App.map);
        document.getElementById("urunFilterList").selectedIndex = this.state.urunFilterValue;
        document.getElementById("urunFilterList").addEventListener("change", this.handleUrunFilter, false);
        var e = document.getElementById("filterList");
        if (e.options[e.selectedIndex].value !== "URUN")
        {
            legendUrunList.remove(App.map);
        }
    }
    createKmlUpload() {
        var style = {color: 'red', opacity: 1.0, fillOpacity: 0.4, weight: 2, clickable: false};
        L.Control.FileLayerLoad.LABEL = '<i class="fa fa-folder-open"></i>';
        L.Control.fileLayerLoad({
            fitBounds: true,
            layerOptions: {
                style: style,
                pointToLayer: function (data, latlng) {
                    return L.circleMarker(latlng, {style: style});
                }
            },
        }).addTo(App.map);
    }
    createWatermark() {
        L.Control.Watermark = L.Control.extend({
            onAdd: function (map) {
                var img = L.DomUtil.create('img');
                img.src = './logo.png';
                img.style.height = '40px';
                return img;
            }
        });

        L.control.watermark = function (opts) {
            return new L.Control.Watermark(opts);
        }

        L.control.watermark({position: 'bottomleft'}).addTo(App.map);
    }
    getColorBunye(d) {
        return d > 2 ? '#081d58' :
            d > 1 ? '#225ea8' :
                d > 0 ? '#7fcdbb' :
                    '#ffffd9';
    }
    getColorOneri(d) {
        return d > 1 ? '#fee8c8' :
            d > 0 ? '#fdbb84' :
                '#e34a33';
    }
    getColorSFG(d) {
        return d > 6 ? '#242826' :
            d > 5 ? '#748282' :
                d > 4 ? '#d8e0e0' :
                    d > 3 ? '#490b58' :
                        d > 2 ? '#3d538d' :
                            d > 1 ? '#1e968d' :
                                d > 0 ? '#75cf54' :
                                    '#fee526';
    }
    getColorURUN(d) {
        return d > 6 ? '#676765' :
                    d > 5 ? '#c9222a' :
                        d > 4 ? '#f36523' :
                            d > 3 ? '#343f96' :
                                d > 2 ? '#96247b' :
                                    d > 1 ? '#46acb1' :
                                        d > 0 ? '#276c4f' :
                                            '#ffcc33';
    }
    getColorBugday(d) {
        return d > 0 ? '#90cdd0' :
                    '#2a676a';
    }
    getColorHashas(d) {
        return d > 0 ? '#c07baf' :
            '#5a1549';
    }
    handleFilter() {
        this.sendLayerContent("");
        legendUrunList.addTo(App.map);
        var e = document.getElementById("filterList");
        var e2 = document.getElementById("urunFilterList");
        this.setState({filterValue: e.selectedIndex});
        this.setState({urunFilterValue: e2.selectedIndex});
        if (e.options[e.selectedIndex].value !== "URUN")
        {
            legendUrunList.remove(App.map);
        }
        this.getAllXml(e.options[e.selectedIndex].value, e2.options[e2.selectedIndex].value);
    }
    handleUrunFilter() {
        this.sendLayerContent("");
        var e = document.getElementById("filterList");
        var e2 = document.getElementById("urunFilterList");
        this.setState({urunFilterValue: e2.selectedIndex});
        this.getAllXml(e.options[e.selectedIndex].value, e2.options[e2.selectedIndex].value);
    }
    handleCenter() {
        var e = document.getElementById("centerList");
        if (e.options[e.selectedIndex].value !== "") {
            var centerObj = e.options[e.selectedIndex].value.toString().split(",");
            App.map.flyTo([parseFloat(centerObj[0]), parseFloat(centerObj[1])], parseFloat(centerObj[2]));
        }
    }
    authenticate(){
        return new Promise(resolve => setTimeout(resolve, 2000))
    }
    componentDidMount(){
        this.authenticate().then(() => {
            const ele = document.getElementById('ipl-progress-indicator')
            if(ele){
                // fade out
                ele.classList.add('available')
                setTimeout(() => {
                    // remove from DOM
                    ele.outerHTML = ''
                }, 2000)
            }
        })
        this.createMap(38.298002, 30.176467, 13);
        legendUrunList.addTo(App.map);
        var e = document.getElementById("filterList");
        var e2 = document.getElementById("urunFilterList");
        this.getAllXml(e.options[e.selectedIndex].value, e2.options[e2.selectedIndex].value);
        if (e.options[e.selectedIndex].value !== "URUN")
        {
            legendUrunList.remove(App.map);
        }
    }
    render() {
        var xml;
        return (
            <div className="full-height App">
                <div id="map" className="full-height map">
                </div>
            </div>
        )
    }
}