import React from 'react';
const FilterRow = (props) => {
    return (
        <tr>
            <td>
                { props.data.name }
            </td>
            <td>
                { props.data.value }
            </td>
            <td>
                { props.data.percent }
            </td>
        </tr>
    );
}

export class Widget extends React.Component {
    constructor(){
        super();
        this.state = {Bunye: "", GubOner: "", SFG: "", SFGtext: "", Alan: "",
            mahalle_alan: "", ilce_alan: "", mahalle_adi: "", ilce_adi: "", filter: "",
            URUN :"", HASAT_TARIH: "",
            filterBunyeValues:["Killi","Orta", "Kumlu", "Cakilli"],
            filterBunyeValuesText:["Killi","Orta", "Kumlu", "Çakıllı"],
            filterOneriValues:["MAP yada TSP, AN, AS, CAN, KNO3 Ürününüze uygun uygulama için danışınız!","DAP, AN, AS, CAN, KNO3 ve K2SO4 + (mikro element gübreleme ihtiyacı) Ürününüze uygun uygulama için danışınız!", "DAP, AN, AS, CAN, KNO3 ve K2SO4 Ürününüze uygun uygulama için danışınız!"],
            filterOneriValuesText:["G1", "G2 + mikro element", "G2"],
            filterSFGvalues:["F1","F2","F3","F4","F5","DogalTarimDisi","SuYuzeyi","YolYerlesim"],
            filterSFGvaluesText: ["Çok Yüksek", "Yüksek", "Orta", "Düşük", "Çok Düşük", "Doğal Tarım Dışı", "Su Yüzeyi", "Yol Yerleşim"],
            data: []}
        this.baseState = this.state;
    }

    componentDidUpdate ()
    {
        if (this.prewLayerContentObj !== this.props.layerContentObj) {
            this.setState(this.baseState);
            let layerContentArray_ = (this.props.layerContentObj.split('<br/>'));
            this.prewLayerContentObj = this.props.layerContentObj;
            var filterName = "";
            var mahalleAdi = "";
            var mahalleAlan = "";
            let data_ = [];
            for (var i = 0; i < layerContentArray_.length; i++) {
                var attributeNamesStr = layerContentArray_[i].toString().substr(3, layerContentArray_[i].indexOf("</b>") - 5);
                let percentValue = ((parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                    layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1))) * 100) / parseFloat(mahalleAlan)).toString().replace(".",",")
                if (attributeNamesStr !== "") {
                    switch (attributeNamesStr) {
                        case ("ID"): {
                            //console.log(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length));
                        break
                        }
                        case ("Bunye"): {
                            this.setState({Bunye: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("GubOner"): {
                            this.setState({GubOner: this.state.filterOneriValuesText[this.state.filterOneriValues.indexOf(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length))]})
                            break
                        }
                        case ("SFG"): {
                            this.setState({SFG: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            this.setState({SFGtext: this.state.filterSFGvaluesText[this.state.filterSFGvalues.indexOf(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length))]})
                            break
                        }
                        case ("Alan"): {
                            this.setState({
                                Alan: (parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                    layerContentArray_[i].indexOf(",") - (layerContentArray_[i].indexOf("</b>") + 1))) /1000).toString().replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da"
                            })
                            break
                        }
                        case ("mahalle_alan"): {
                            mahalleAlan = layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1));
                            this.setState({
                                mahalle_alan: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                    layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da"
                            });
                            break
                        }
                        case ("Mahalle"): {
                            mahalleAdi = layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length);
                            this.setState({mahalle_adi: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("ilce_alan"): {
                            this.setState({
                                ilce_alan: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                    layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da"
                            });
                            break
                        }
                        case ("İlçe"): {
                            this.setState({ilce_adi: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("filter"): {
                            switch (layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)) {
                                case("Bunye"): {
                                    this.setState({filter: "Bünye"});
                                    filterName = "Bünye";
                                    break
                                }
                                case("GubOner"): {
                                    this.setState({filter: "Öneri"});
                                    filterName = "Öneri";
                                    break
                                }
                                case("SFG"): {
                                    this.setState({filter: "Verim"});
                                    filterName = "SFG";
                                    break
                                }
                                default:
                                {
                                    break
                                }
                            }
                            break
                        }
                        case ("URUN"): {
                            this.setState({URUN: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        case ("HASAT_TARI"): {
                            this.setState({HASAT_TARIH: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4, layerContentArray_[i].length)})
                            break
                        }
                        default: {
                            switch (filterName) {
                                case("Bünye"): {
                                    if (attributeNamesStr.toString().substr(0, attributeNamesStr.indexOf("_")) === mahalleAdi) {
                                        data_.push({
                                            name: this.state.filterBunyeValuesText[this.state.filterBunyeValues.indexOf(attributeNamesStr.toString().substr(attributeNamesStr.indexOf("_") + 1,
                                                attributeNamesStr.length - (attributeNamesStr.indexOf("_") + 1)))],
                                            value: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da",
                                            percent: "%" + percentValue.substr(0, percentValue.indexOf(",") + 2) ,
                                            valueNum: parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",","))});
                                    }
                                    break
                                }
                                case("Öneri"): {
                                    if (attributeNamesStr.toString().substr(0, attributeNamesStr.indexOf("_")) === mahalleAdi) {
                                        data_.push({
                                            name: this.state.filterOneriValuesText[this.state.filterOneriValues.indexOf(attributeNamesStr.toString().substr(attributeNamesStr.indexOf("_") + 1,
                                                attributeNamesStr.length - (attributeNamesStr.indexOf("_") + 1)))],
                                            value: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da",
                                            percent: "%" + percentValue.substr(0, percentValue.indexOf(",") + 2),
                                            valueNum: parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",","))});
                                    }
                                    break
                                }
                                case("SFG"): {
                                    if (attributeNamesStr.toString().substr(0, attributeNamesStr.indexOf("_")) === mahalleAdi) {
                                        data_.push({
                                            name: this.state.filterSFGvaluesText[this.state.filterSFGvalues.indexOf(attributeNamesStr.toString().substr(attributeNamesStr.indexOf("_") + 1,
                                                attributeNamesStr.length - (attributeNamesStr.indexOf("_") + 1)))],
                                            value: layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " da",
                                            percent: "%" + percentValue.substr(0, percentValue.indexOf(",") + 2),
                                            valueNum: parseFloat(layerContentArray_[i].toString().substr(layerContentArray_[i].indexOf("</b>") + 4,
                                                layerContentArray_[i].indexOf(".") - (layerContentArray_[i].indexOf("</b>") + 1)).replace(".",","))});
                                    }
                                    break
                                }
                                default:
                                {
                                    break
                                }
                            }
                            break
                        }
                    }
                }
            }
            data_.sort(function (a, b) {
                return a.valueNum - b.valueNum;
            });
            let _data_ = []
            for (i = data_.length - 1; i >= 0 ; i--)
            {
                _data_.push(data_[i]);
            }
            this.setState({data: _data_});
        }
    }

    render() {
        var prewLayerContentObj;
        let counter = 0;
        var rows = this.state.data.map(filtre => {
            return <FilterRow
                key = {
                    counter++
                }
                data = {
                    filtre
                }
            />
        })
        return(
            <div id="widget" className="full-height col-12">
                <input type="hidden" id="hiddenInput" value={this.props.layerContentObj}/>
                <div className={"firstTable"}>
                    <table className="table table-hover table-dark">
                        <thead>
                        <tr>
                            <th colSpan="2">Toplamlar:</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>İlçe Adı: </td>
                            <td>{this.state.ilce_adi}</td>
                        </tr>
                        <tr>
                            <td>İlçe Alan: </td>
                            <td>{this.state.ilce_alan}</td>
                        </tr>
                        <tr>
                            <td>Mahalle Adı: </td>
                            <td>{this.state.mahalle_adi}</td>
                        </tr>
                        <tr>
                            <td>Mahalle Alan: </td>
                            <td>{this.state.mahalle_alan}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <table className="table table-hover table-dark">
                        <thead>
                        <tr>
                            <th colSpan="2">{this.state.mahalle_adi} Mahallesi {this.state.filter} Değerleri:</th>
                        </tr>
                        </thead>
                        <tbody className={"sortedDatasTable"}>
                        {rows}
                        </tbody>
                    </table>
                </div>

                <div>
                <table className="table table-hover table-dark">
                    <thead>
                    <tr>
                        <th colSpan="2">Parsel Değerleri:</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Bünye:</td>
                        <td>{this.state.Bunye}</td>
                    </tr>
                    <tr>
                        <td>Öneri:</td>
                        <td>{this.state.GubOner}</td>
                    </tr>
                    <tr>
                        <td>Verim:</td>
                        <td>{this.state.SFGtext}</td>
                    </tr>
                    <tr>
                        <td>Alan:</td>
                        <td>{this.state.Alan}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
        )
    }
}
